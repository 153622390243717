let enums = {};

enums.DNA_COUNTRIES = {
  US: { value: "US", text: "United States" },
  CA: { value: "CA", text: "Canada" },
  PL: { value: "PL", text: "Poland" },
  MX: { value: "MX", text: "Mexico" },
};

enums.WORLD_COUNTRIES_LIST = [
  { name: "United States of America (the)", code: "US" },
  { name: "Albania", code: "AL" },
  { name: "Åland Islands", code: "AX" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas (the)", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia (Plurinational State of)", code: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory (the)", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cayman Islands (the)", code: "KY" },
  { name: "Central African Republic (the)", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands (the)", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros (the)", code: "KM" },
  { name: "Congo (the Democratic Republic of the)", code: "CD" },
  { name: "Congo (the)", code: "CG" },
  { name: "Cook Islands (the)", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic (the)", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (the) [Malvinas]", code: "FK" },
  { name: "Faroe Islands (the)", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories (the)", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia (the)", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and McDonald Islands", code: "HM" },
  { name: "Holy See (the)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran (Islamic Republic of)", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea (the Democratic People's Republic of)", code: "KP" },
  { name: "Korea (the Republic of)", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic (the)", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands (the)", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia (Federated States of)", code: "FM" },
  { name: "Moldova (the Republic of)", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands (the)", code: "NL" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger (the)", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands (the)", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine, State of", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines (the)", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Republic of North Macedonia", code: "MK" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation (the)", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Réunion", code: "RE" },
  { name: "Saint Barthélemy", code: "BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin (French part)", code: "MF" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten (Dutch part)", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan (the)", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan (Province of China)", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands (the)", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates (the)", code: "AE" },
  { name: "United Kingdom of Great Britain and Northern Ireland (the)", code: "GB" },
  { name: "United States Minor Outlying Islands (the)", code: "UM" },
  // { name: "United States of America (the)", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands (British)", code: "VG" },
  { name: "Virgin Islands (U.S.)", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

enums.US_STATES = {
  AL: { value: "AL", text: "Alabama" },
  AK: { value: "AK", text: "Alaska" },
  AR: { value: "AR", text: "Arkansas" },
  AZ: { value: "AZ", text: "Arizona" },
  CA: { value: "CA", text: "California" },
  CO: { value: "CO", text: "Colorado" },
  CT: { value: "CT", text: "Connecticut" },
  DC: { value: "DC", text: "D.C." },
  DE: { value: "DE", text: "Delaware" },
  FL: { value: "FL", text: "Florida" },
  GA: { value: "GA", text: "Georgia" },
  HI: { value: "HI", text: "Hawaii" },
  IA: { value: "IA", text: "Iowa" },
  ID: { value: "ID", text: "Idaho" },
  IL: { value: "IL", text: "Illinois" },
  IN: { value: "IN", text: "Indiana" },
  KS: { value: "KS", text: "Kansas" },
  KY: { value: "KY", text: "Kentucky" },
  LA: { value: "LA", text: "Louisiana" },
  MA: { value: "MA", text: "Massachusetts" },
  MD: { value: "MD", text: "Maryland" },
  ME: { value: "ME", text: "Maine" },
  MI: { value: "MI", text: "Michigan" },
  MN: { value: "MN", text: "Minnesota" },
  MO: { value: "MO", text: "Missouri" },
  MS: { value: "MS", text: "Mississippi" },
  MT: { value: "MT", text: "Montana" },
  NC: { value: "NC", text: "North Carolina" },
  ND: { value: "ND", text: "North Dakota" },
  NE: { value: "NE", text: "Nebraska" },
  NH: { value: "NH", text: "New Hampshire" },
  NJ: { value: "NJ", text: "New Jersey" },
  NM: { value: "NM", text: "New Mexico" },
  NV: { value: "NV", text: "Nevada" },
  NY: { value: "NY", text: "New York" },
  OK: { value: "OK", text: "Oklahoma" },
  OH: { value: "OH", text: "Ohio" },
  OR: { value: "OR", text: "Oregon" },
  PA: { value: "PA", text: "Pennsylvania" },
  RI: { value: "RI", text: "Rhode Island" },
  SC: { value: "SC", text: "South Carolina" },
  SD: { value: "SD", text: "South Dakota" },
  TN: { value: "TN", text: "Tennessee" },
  TX: { value: "TX", text: "Texas" },
  UT: { value: "UT", text: "Utah" },
  VA: { value: "VA", text: "Virginia" },
  VT: { value: "VT", text: "Vermont" },
  WA: { value: "WA", text: "Washington" },
  WI: { value: "WI", text: "Wisconsin" },
  WV: { value: "WV", text: "West Virginia" },
  WY: { value: "WY", text: "Wyoming" },
};

enums.MX_STATES = {
  Aguascalientes: { value: "Aguascalientes", text: "Aguascalientes" },
  BajaCalifornia: { value: "Baja California", text: "Baja California" },
  BajaCaliforniaSur: { value: "Baja California Sur", text: "Baja California Sur" },
  Campeche: { value: "Campeche", text: "Campeche" },
  CoahuilaDeZaragoza: { value: "Coahuila de Zaragoza", text: "Coahuila de Zaragoza" },
  Colima: { value: "Colima", text: "Colima" },
  Chiapas: { value: "Chiapas", text: "Chiapas" },
  Chihuahua: { value: "Chihuahua", text: "Chihuahua" },
  DistritoFederal: { value: "DistritoFederal", text: "DistritoFederal" },
  Durango: { value: "Durango", text: "Durango" },
  Guanajuato: { value: "Guanajuato", text: "Guanajuato" },
  Guerrero: { value: "Guerrero", text: "Guerrero" },
  Hidalgo: { value: "Hidalgo", text: "Hidalgo" },
  Jalisco: { value: "Jalisco", text: "Jalisco" },
  Mexico: { value: "México", text: "México" },
  MichoacanDeOcampo: { value: "Michoacán de Ocampo", text: "Michoacán de Ocampo" },
  Morelos: { value: "Morelos", text: "Morelos" },
  Nayarit: { value: "Nayarit", text: "Nayarit" },
  NuevoLeon: { value: "Nuevo León", text: "Nuevo León" },
  Oaxaca: { value: "Oaxaca", text: "Oaxaca" },
  Puebla: { value: "Puebla", text: "Puebla" },
  Queretaro: { value: "Querétaro", text: "Querétaro" },
  QuintanaRoo: { value: "Quintana Roo", text: "Quintana Roo" },
  SanLuisPotosi: { value: "Quintana Roo", text: "SanLuisPotosí" },
  Sinaloa: { value: "Sinaloa", text: "Sinaloa" },
  Sonora: { value: "Sonora", text: "Sonora" },
  Tabasco: { value: "Tabasco", text: "Tabasco" },
  Tamaulipas: { value: "Tamaulipas", text: "Tamaulipas" },
  Tlaxcala: { value: "Tlaxcala", text: "Tlaxcala" },
  VeracruzDeIgnacio: {
    value: "Veracruz de Ignacio de la Llave",
    text: "Veracruz de Ignacio de la Llave",
  },
  Yucatan: { value: "Yucatán", text: "Yucatán" },
  Zacatecas: { value: "Zacatecas", text: "Zacatecas" },
};

enums.CA_PROVINCES = {
  Alberta: { value: "Alberta", text: "Alberta" },
  BritishColumbia: { value: "British Columbia", text: "British Columbia" },
  Manitoba: { value: "Manitoba", text: "Manitoba" },
  NewBrunswick: { value: "New Brunswick", text: "New Brunswick" },
  NewfoundlandAndLabrador: {
    value: "Newfoundland and Labrador",
    text: "Newfoundland and Labrador",
  },
  NovaScotia: { value: "Nova Scotia", text: "Nova Scotia" },
  NorthwestTerritory: { value: "Northwest Territory", text: "Northwest Territory" },
  NunavutTerritory: { value: "Nunavut Territory", text: "Nunavut Territory" },
  Ontario: { value: "Ontario", text: "Ontario" },
  PrinceEdwardIsland: { value: "Prince Edward Island", text: "Prince Edward Island" },
  Quebec: { value: "Quebec", text: "Quebec" },
  Saskatchewan: { value: "Saskatchewan", text: "Saskatchewan" },
  Yukon: { value: "Yukon", text: "Yukon" },
};

enums.PL_PROVINCES = {
  LowerSilesia: { value: "Lower Silesia", text: "Lower Silesia" },
  KujawskoPomorskie: { value: "Kujawsko-Pomorskie", text: "Kujawsko-Pomorskie" },
  LodzVoivodeship: { value: "Łódź Voivodeship", text: "Łódź Voivodeship" },
  Lublin: { value: "Lublin", text: "Lublin" },
  Lubusz: { value: "Lubusz", text: "Lubusz" },
  LesserPoland: { value: "Lesser Poland", text: "Lesser Poland" },
  Mazovia: { value: "Mazovia", text: "Mazovia" },
  OpoleVoivodeship: { value: "Opole Voivodeship", text: "Opole Voivodeship" },
  Subcarpathia: { value: "Subcarpathia", text: "Subcarpathia" },
  Podlasie: { value: "Podlasie", text: "Podlasie" },
  Pomerania: { value: "Pomerania", text: "Pomerania" },
  Silesia: { value: "Silesia", text: "Silesia" },
  Swietokrzyskie: { value: "Świętokrzyskie", text: "Świętokrzyskie" },
  WarmiaMasuria: { value: "Warmia-Masuria", text: "Warmia-Masuria" },
  GreaterPoland: { value: "Greater Poland", text: "Greater Poland" },
  WestPomerania: { value: "West Pomerania", text: "West Pomerania" },
};

enums.RECENT_TYPE = {
  Created: { value: 1, text: "Created" },
  Updated: { value: 2, text: "Updated" },
  Accessed: { value: 3, text: "Accessed" },
};

enums.CLIENT_TYPE = [
  { value: 0, text: "Revenue" },
  { value: 1, text: "Complexity" },
  { value: 2, text: "Projects Count" },
];

enums.ActivityLogType = {
  Automatic: { value: 1, text: "Automatic" },
  Manual: { value: 2, text: "Manual" },
  RelatedActions: { value: 3, text: "Related Actions" },
};

enums.REPORT_TYPE = {
  BOM: {
    value: 1,
    text: "BOM",
    export: "xlsx",
    fullText: "Bill of Materials",
    icon: "fad fa-file-invoice-dollar",
  },
  ExtendedBOM: {
    value: 2,
    text: "Extended BOM",
    export: "xlsx",
    fullText: "Extended Bill of Materials",
    icon: "fad fa-file-invoice-dollar fa-swap-opacity",
  },
  PIF: {
    value: 3,
    text: "PIF",
    export: "xlsx",
    fullText: "Price Intake Form",
    icon: "fad fa-file-chart-pie",
  },
  OPC: {
    value: 4,
    text: "OPC",
    export: "xlsx",
    fullText: "Opinion of Probable Cost",
    icon: "fad fa-file-exclamation",
  },
  SpaceList: {
    value: 5,
    text: "Space List",
    export: "xlsx",
    fullText: "List of Project's Spaces",
    icon: "fad fa-ballot",
  },
  BIN: {
    value: 6,
    text: "BIN",
    export: "docx",
    fullText: "Bid Instruction Narrative",
    icon: "fad fa-file-contract",
  },
  DrawingSheetList: {
    value: 7,
    text: "Drawing Sheet List",
    export: "xlsx",
    fullText: "List of Drawing Sheets",
    icon: "fad fa-drafting-compass",
  },
  AreaGroupsBOM: {
    value: 8,
    text: "Area Groups BOM",
    export: "xlsx",
    fullText: "BOM with Area Groups",
    icon: "fad fa-file-invoice",
  },
  Proposal: {
    value: 9,
    text: "Client Proposal",
    export: "docx",
    fullText: "Client Proposal for a Contract",
    icon: "fad fa-file-contract",
  },
  RevitFamilies: {
    value: 10,
    text: "Revit Families",
    export: "xlsx",
    fullText: "Revit Families",
    icon: "fad fa-square-r",
  },
};

enums.PROJECT_VARIABLE_TYPES = {
  FreeText: {
    value: 1,
    text: "Free Text",
    icon: "fa-text",
  },
  RichText: {
    value: 2,
    text: "Rich Text",
    icon: "fa-font-case",
  },
  Number: {
    value: 3,
    text: "Number",
    icon: "fa-dice",
  },
  Boolean: {
    value: 3,
    text: "Boolean",
    icon: "fa-check-circle",
  },
  Counter: {
    value: 4,
    text: "Counter",
    icon: "fa-plus-circle",
  },
  NamingTemplate: {
    value: 5,
    text: "Naming Template",
    icon: "fa-pen-field",
  },
};

enums.LEAD_STATUS = {
  Lead: {
    value: 1,
    text: "New Lead",
    icon: "fa-flag",
    color: "light-blue",
    textColor: "white",
  },
  Waiting: {
    value: 2,
    text: "Waiting AC Assignment",
    icon: "fa-spinner",
    color: "amber",
    textColor: "grey darken-4",
  },
  Proposal: {
    value: 3,
    text: "Researching Lead",
    icon: "fa-file-search",
    color: "deep-purple",
    textColor: "white",
  },
  LeadLost: {
    value: 4,
    text: "Lead Lost",
    icon: "fa-handshake-slash",
    color: "#2F4858",
    textColor: "white",
  },
  PausedClient: {
    value: 5,
    text: "Paused by Client",
    icon: "fa-user-slash",
    color: "#C13588",
    textColor: "white",
  },
  PausedDNA: {
    value: 6,
    text: "Paused by DNA",
    icon: "fa-pause",
    color: "pink",
    textColor: "white",
  },
};

enums.LEAD_STATUS_FLAT_LIST =
  Object.entries(enums.LEAD_STATUS).flatMap((EnumItem) => {
    if (EnumItem[1].SUB_STATUS != null) {
      let subs =
        Object.entries(EnumItem[1].SUB_STATUS).flatMap((subItem) => {
          return [subItem[1]];
        }) || [];
      return [EnumItem[1], ...subs];
    }
    return EnumItem[1];
  }) || [];

enums.PROJECT_STATUS_OLD = {
  Lead: {
    value: 1,
    text: "Lead",
    icon: "fa-flag",
    color: "light-blue",
    textColor: "white",
  },
  Opportunity: {
    value: 2,
    text: "Opportunity",
    icon: "fa-fire-alt",
    color: "amber",
    textColor: "grey darken-4",
  },
  Proposal: {
    value: 3,
    text: "Proposal",
    icon: "fa-scroll",
    color: "deep-purple",
    textColor: "white",
    SUB_STATUS: {
      Opportunity: {
        value: 4,
        parent: 3,
        text: "Requested",
        icon: "fa-edit",
        color: "blue-grey",
        textColor: "white",
      },
      InProgress: {
        value: 5,
        parent: 3,
        text: "In Progress",
        icon: "fa-play",
        color: "orange",
        textColor: "white",
      },
      Sent: {
        value: 6,
        parent: 3,
        text: "Sent",
        icon: "fa-paper-plane",
        color: "cyan",
        textColor: "grey darken-4",
      },
      Accepted: {
        value: 7,
        parent: 3,
        text: "Accepted",
        icon: "fa-thumbs-up",
        color: "green",
        textColor: "white",
      },
      NotAccepted: {
        value: 8,
        parent: 3,
        text: "Not Accepted",
        icon: "fa-thumbs-down",
        color: "pink",
        textColor: "white",
      },
    },
  },
  InProgress: {
    value: 9,
    text: "In Progress",
    icon: "fa-play",
    color: "green",
    textColor: "white",
  },
  Paused: {
    value: 10,
    text: "Paused",
    icon: "fa-pause",
    color: "pink",
    textColor: "white",
  },
  Closed: {
    value: 11,
    text: "Closed",
    icon: "fa-do-not-enter",
    color: "red",
    textColor: "white",
  },
  Archived: {
    value: 12,
    text: "Archived",
    icon: "fa-archive",
    color: "brown",
    textColor: "white",
  },
};

enums.PROJECT_STATUS = {
  Paused_By_Client: {
    value: 1,
    text: "Paused by Client",
    icon: "fa-user-slash",
    color: "pink",
    textColor: "white",
  },
  Paused_By_DNA: {
    value: 2,
    text: "Paused by DNA",
    icon: "fa-pause",
    color: "red",
    textColor: "white",
  },
  Proposal_Requested: {
    value: 100,
    text: "Proposal Requested",
    icon: "fa-scroll",
    color: "blue-grey",
    textColor: "white",
  },
  Proposal_InProgress: {
    value: 101,
    text: "Proposal In Progress",
    icon: "fa-play",
    color: "orange",
    textColor: "white",
  },
  Proposal_Sent: {
    value: 102,
    text: "Proposal Sent",
    icon: "fa-paper-plane",
    color: "cyan",
    textColor: "white",
  },
  Proposal_Update_Requested: {
    value: 103,
    text: "Proposal Update Requested",
    icon: "fa-pencil",
    color: "amber",
    textColor: "grey darken-4",
  },
  Proposal_Accepted: {
    value: 104,
    text: "Proposal Accepted",
    icon: "fa-check-circle",
    color: "green",
    textColor: "white",
  },
  Proposal_NotAccepted: {
    value: 105,
    text: "Proposal Not Accepted",
    icon: "fa-times-circle",
    color: "purple",
    textColor: "white",
  },
  Project_Design_In_Progress: {
    value: 200,
    text: "Design In Progress",
    icon: "fa-play",
    color: "orange",
    textColor: "white",
  },
  Project_Design_Complete: {
    value: 201,
    text: "Design Complete",
    icon: "fa-check-circle",
    color: "green",
    textColor: "white",
  },
  Project_RFP_Prepared: {
    value: 202,
    text: "RFP Prepared",
    icon: "fa-pen-nib",
    color: "amber",
    textColor: "grey darken-4",
  },
  Project_RFP_Out: {
    value: 203,
    text: "RFP Out",
    icon: "fa-paper-plane",
    color: "light-blue",
    textColor: "white",
  },
  Project_RFP_Received: {
    value: 204,
    text: "RFP Received",
    icon: "fa-inbox-in",
    color: "deep-purple",
    textColor: "white",
  },
  Project_Construction_Administration: {
    value: 205,
    text: "Construction Administration",
    icon: "fa-hard-hat",
    color: "cyan",
    textColor: "white",
  },
  Closed: {
    value: 300,
    text: "Closed",
    icon: "fa-do-not-enter",
    color: "red",
    textColor: "white",
  },
  Archived: {
    value: 400,
    text: "Archived",
    icon: "fa-archive",
    color: "brown",
    textColor: "white",
  },
};

enums.PROJECT_STATUS_FLAT_LIST =
  Object.entries(enums.PROJECT_STATUS_OLD).flatMap((EnumItem) => {
    if (EnumItem[1].SUB_STATUS != null) {
      let subs =
        Object.entries(EnumItem[1].SUB_STATUS).flatMap((subItem) => {
          return [subItem[1]];
        }) || [];
      return [EnumItem[1], ...subs];
    }
    return EnumItem[1];
  }) || [];

enums.CLIENT_VARIABLE_TYPES = {
  FreeText: {
    value: 1,
    text: "Free Text",
    icon: "fa-text",
  },
  RichText: {
    value: 2,
    text: "Rich Text",
    icon: "fa-font-case",
  },
  Number: {
    value: 3,
    text: "Number",
    icon: "fa-dice",
  },
  Counter: {
    value: 4,
    text: "Counter",
    icon: "fa-plus-circle",
  },
};

enums.INDUSTRY_TYPE = {
  AUDIO: {
    value: 1,
    text: "AUDIO",
  },
  AUDIO_VIDEO: {
    value: 2,
    text: "AUDIO VIDEO",
  },
  AV_CONTROL: {
    value: 3,
    text: "AV CONTROL",
  },
  COMPUTING: {
    value: 4,
    text: "COMPUTING",
  },
  FIRE_ALARM_LIFE_SAFETY: {
    value: 5,
    text: "FIRE ALARM & LIFE SAFETY",
  },
  NETWORKING: {
    value: 6,
    text: "NETWORKING",
  },
  SECURITY: {
    value: 7,
    text: "SECURITY",
  },
  SOFTWARE: {
    value: 8,
    text: "SOFTWARE",
  },
  TELECOM_INFRASTRUCTURE: {
    value: 9,
    text: "TELECOM INFRASTRUCTURE",
  },
  TELEPHONY: {
    value: 10,
    text: "TELEPHONY",
  },
  VIDEO: {
    value: 11,
    text: "VIDEO",
  },
  LIGHTING: {
    value: 12,
    text: "LIGHTING",
  },
  UNDEFINED: {
    value: 200,
    text: "UNDEFINED",
  },
  OTHER: {
    value: 201,
    text: "OTHER",
  },
};

enums.DOCUMENT_LAYOUT = {
  BimTeamDecided: {
    value: 1,
    text: "BIM Team Decided",
    icon: "fa-building",
  },
  OneViewPerPage: {
    value: 2,
    text: "1 View Per Page",
    icon: "fa-dice-one fa-swap-opacity",
  },
  FourViewsPerPage: {
    value: 3,
    text: "4 Views Per Page",
    icon: "fa-dice-four fa-swap-opacity",
  },
  SixViewsPerPage: {
    value: 4,
    text: "6 Views Per Page",
    icon: "fa-dice-six fa-swap-opacity",
  },
};

enums.ASSOCIATE_TYPE = {
  NotAssociated: {
    value: 0,
    text: "Not Associated",
    icon: "fa-link-simple-slash",
  },
  Space: {
    value: 1,
    text: "Space",
    icon: "fa-table-pivot",
  },
  Level: {
    value: 2,
    text: "Level",
    icon: "fa-layer-group",
  },
  Building: {
    value: 3,
    text: "Building",
    icon: "fa-building",
  },
  Category: {
    value: 4,
    text: "Category",
    icon: "fa-shapes",
  },
};

enums.ASSOCIATE_TYPE_LIST = [
  enums.ASSOCIATE_TYPE.NotAssociated,
  enums.ASSOCIATE_TYPE.Space,
  enums.ASSOCIATE_TYPE.Level,
  enums.ASSOCIATE_TYPE.Building,
  enums.ASSOCIATE_TYPE.Category,
];

enums.DOCUMENT_LAYOUT_LIST = [
  enums.DOCUMENT_LAYOUT.BimTeamDecided,
  enums.DOCUMENT_LAYOUT.OneViewPerPage,
  enums.DOCUMENT_LAYOUT.FourViewsPerPage,
  enums.DOCUMENT_LAYOUT.SixViewsPerPage,
];

enums.DOCUMENT_TYPES = {
  IAV: {
    value: 1,
    text: "IAV",
    desc: "Infrastructure for Audiovisual",
    color: "blue",
    textColor: "white",
    icon: "fa-video",
  },
  SCD: {
    value: 2,
    text: "SCD",
    desc: "System Cabling Diagram",
    color: "cyan",
    textColor: "white",
    icon: "fa-wave-square",
  },
  CoverSheet: {
    value: 3,
    text: "CS",
    desc: "Cover Sheet",
    color: "purple",
    textColor: "white",
    icon: "fa-scroll",
  },
  ScopeView: {
    value: 4,
    text: "SV",
    desc: "Scope View Sheet",
    color: "pink",
    textColor: "white",
    icon: "fa-binoculars",
  },
  SitePlan: {
    value: 5,
    text: "SP",
    desc: "Site Plan",
    color: "orange darken-1",
    textColor: "white",
    icon: "fa-drafting-compass",
  },
  SpecSheet: {
    value: 8,
    text: "SPEC",
    desc: "Product Specification Sheet",
    color: "green",
    textColor: "white",
    icon: "fa-file-spreadsheet",
  },
  TypicalIAV: {
    value: 6,
    text: "TPCL IAV",
    desc: "Typical Infrastructure for Audiovisual",
    color: "blue accent-4",
    textColor: "white",
    icon: "fa-play",
  },
  TypicalSCD: {
    value: 7,
    text: "TPCL SCD",
    desc: "Typical System Cabling Diagram",
    color: "cyan accent-4",
    textColor: "white",
    icon: "fa-project-diagram",
  },
  Plan: {
    value: 9,
    text: "Plan",
    desc: "Plan",
    color: "purple accent-4",
    textColor: "white",
    icon: "fa-clipboard-list-check",
  },
  Overview: {
    value: 10,
    text: "Overview",
    desc: "Overview",
    color: "teal accent-4",
    textColor: "white",
    icon: "fa-eye",
  },
  Detail: {
    value: 11,
    text: "Detail",
    desc: "Detail",
    color: "pink accent-4",
    textColor: "white",
    icon: "fa-memo-circle-info",
  },
};

enums.DOCUMENT_TYPES_LIST = [
  enums.DOCUMENT_TYPES.IAV,
  enums.DOCUMENT_TYPES.SCD,
  enums.DOCUMENT_TYPES.CoverSheet,
  enums.DOCUMENT_TYPES.ScopeView,
  enums.DOCUMENT_TYPES.SitePlan,
  enums.DOCUMENT_TYPES.TypicalIAV,
  enums.DOCUMENT_TYPES.TypicalSCD,
  enums.DOCUMENT_TYPES.SpecSheet,
];

enums.DOCUMENT_STATE = {
  Created: {
    value: 1,
    text: "Created",
    icon: "fa-flag-checkered",
    color: "light-blue",
    textColor: "white",
  },
  InProgress: {
    value: 2,
    text: "In Progress",
    icon: "fa-play",
    color: "amber",
    textColor: "grey darken-4",
  },
  Completed: {
    value: 3,
    text: "Completed",
    icon: "fa-check-circle",
    color: "green",
    textColor: "white",
  },
  Cancelled: { value: 4, text: "Cancelled", icon: "fa-times", color: "red", textColor: "white" },
  OnHold: {
    value: 5,
    text: "On Hold",
    icon: "fa-pause",
    color: "pink",
    textColor: "white",
  },
  Archived: {
    value: 6,
    text: "Archived",
    icon: "fa-archive",
    color: "brown",
    textColor: "white",
  },
};

enums.DOCUMENT_STATE_LIST = [
  enums.DOCUMENT_STATE.Created,
  enums.DOCUMENT_STATE.InProgress,
  enums.DOCUMENT_STATE.Completed,
  enums.DOCUMENT_STATE.Cancelled,
  enums.DOCUMENT_STATE.OnHold,
  enums.DOCUMENT_STATE.Archived,
];

enums.SPACE_STATUS = {
  Created: {
    value: 1,
    text: "Created",
    icon: "fa-flag-checkered",
    color: "light-blue",
    textColor: "white",
  },
  InProgress: {
    value: 2,
    text: "In Progress",
    icon: "fa-play",
    color: "amber",
    textColor: "grey darken-4",
  },
  Completed: {
    value: 3,
    text: "Completed",
    icon: "fa-check-circle",
    color: "green",
    textColor: "white",
  },
  Cancelled: { value: 4, text: "Cancelled", icon: "fa-times", color: "red", textColor: "white" },
  OnHold: {
    value: 5,
    text: "On Hold",
    icon: "fa-pause",
    color: "pink",
    textColor: "white",
  },
  Archived: {
    value: 6,
    text: "Archived",
    icon: "fa-archive",
    color: "brown",
    textColor: "white",
  },
};

enums.CONTRACT_STATUS = {
  Draft: {
    value: 1,
    text: "Draft",
    icon: "fa-pen",
    color: "blue-grey",
    textColor: "white",
    cssClass: "contract-status-draft",
  },
  Pending: {
    value: 2,
    text: "Pending Approval",
    // icon: "fa-hourglass-start",
    icon: "fa-gavel",
    color: "orange",
    textColor: "secondary",
    cssClass: "contract-status-pending",
  },
  Approved: {
    value: 3,
    text: "Approved",
    icon: "fa-check",
    color: "green",
    textColor: "white",
    cssClass: "contract-status-approved",
  },
};

enums.BIG_BOARD_TYPE = {
  BigBoard: {
    value: 1,
    text: "Big Board",
    icon: "fa-dove fa-swap-opacity",
    fullPage: true,
  },
  SmallBoard: {
    value: 2,
    text: "Small Board",
    icon: "fa-crow",
    fullPage: false,
  },
  MyBoard: {
    value: 3,
    text: "My Board",
    icon: "fa-bird fa-swap-opacity",
    fullPage: true,
  },
};

enums.CONSTRUCTION_PHASE_TYPE = {
  SD: {
    value: 1,
    text: "SD",
    description: "Schematic Design",
    icon: "fa-circle",
    color: "green",
    textColor: "white",
  },
  DD: {
    value: 2,
    text: "DD",
    description: "Design Development",
    icon: "fa-circle",
    color: "green",
    textColor: "white",
  },
  CD: {
    value: 3,
    text: "CD",
    description: "Construction Documents",
    icon: "fa-circle",
    color: "green",
    textColor: "white",
  },
  BID: {
    value: 4,
    text: "BID",
    description: "Bidding/Negotiations",
    icon: "fa-circle",
    color: "green",
    textColor: "white",
  },
  CA: {
    value: 5,
    text: "CA",
    description: "Construction Administration",
    icon: "fa-circle",
    color: "green",
    textColor: "white",
  },
  Pause: {
    value: 6,
    text: "Pause",
    description: "Paused Phase",
    icon: "fa-circle",
    color: "orange",
    textColor: "black",
  },
  NIC: {
    value: 7,
    text: "NIC",
    description: "Not In Contract",
    icon: "fa-circle",
    color: "red",
    textColor: "white",
  },
};

enums.LABOR_TYPE = {
  AC: {
    value: 1,
    text: "AC",
    desc: "Associate Consultants",
    groupClass: "default-group",
  },
  SC: {
    value: 2,
    text: "SC",
    desc: "Senior Consultants",
    groupClass: "default-group",
  },
  C: {
    value: 3,
    text: "C",
    desc: "Consultants",
    groupClass: "default-group",
  },
  VI: {
    value: 4,
    text: "VI",
    desc: "Virtual Integrators",
    groupClass: "default-group",
  },
  BIM: {
    value: 5,
    text: "BIM",
    desc: "BIM Specialists",
    groupClass: "default-group",
  },
  MGT: {
    value: 6,
    text: "Management",
    desc: "Management",
    groupClass: "default-group",
  },
  TCN: {
    value: 7,
    text: "TCN",
    desc: "Technical",
    groupClass: "default-group",
  },
  SCD: {
    value: 8,
    text: "SCD",
    desc: "SCD",
    groupClass: "default-group",
  },
  Hours: {
    value: -1,
    text: "Hours",
    desc: "Labor Hours Summary",
    groupClass: "hours-group",
    groupIcon: "fa-stopwatch",
  },
  Revenue: {
    value: -2,
    text: "Revenue",
    desc: "Labor Revenue Summary",
    groupClass: "revenue-group",
    groupIcon: "fa-circle-dollar",
  },
  Labor: {
    value: -3,
    text: "Cost",
    desc: "Labor Cost Summary",
    groupClass: "cost-group",
    groupIcon: "fa-money-check-dollar",
  },
  DirectExpensesRevenue: {
    value: -4,
    text: "Direct Expenses Revenue",
    desc: "Direct Expenses Revenue Summary",
    groupClass: "direct-expenses-revenue-group",
    groupIcon: "fa-circle-dollar-to-slot",
  },
  DirectExpensesCost: {
    value: -5,
    text: "Direct Expenses Cost",
    desc: "Direct Expenses Cost Summary",
    groupClass: "direct-expenses-cost-group",
    groupIcon: "fa-money-from-bracket",
  },
  NetSummary: {
    value: -6,
    text: "Net Summary",
    desc: "Net Summary",
    groupClass: "net-summary-group",
    groupIcon: "fa-sigma",
    groupDark: true,
  },
};
enums.LABOR_TYPE_LIST = [
  enums.LABOR_TYPE.AC,
  enums.LABOR_TYPE.SC,
  enums.LABOR_TYPE.C,
  enums.LABOR_TYPE.VI,
  enums.LABOR_TYPE.BIM,
  enums.LABOR_TYPE.MGT,
  enums.LABOR_TYPE.TCN,
  enums.LABOR_TYPE.SCD,
];
//Direct Expense
enums.DIRECT_EXPENSE_CATEGORY = {
  Travel: {
    value: 0,
    text: "Travel",
    icon: "fa-car-side",
    groupClass: "default-group",
  },
  SubContractor: {
    value: 1,
    text: "SubContractor",
    icon: "fa-user-helmet-safety",
    groupClass: "default-group",
  },
  Misc: {
    value: 2,
    text: "Miscellaneous",
    icon: "fa-circle-dollar",
    groupClass: "default-group",
  },
};

enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE = {
  Custom: {
    value: 1,
    text: "Custom",
  },
  Month: {
    value: 2,
    text: "Month",
  },
};

enums.PROJECT_TYPE = {
  Build: {
    value: 1,
    text: "Build",
    capped: "Build",
    letter: "B",
  },
  Client: {
    value: 2,
    text: "Client",
    capped: "Client",
    letter: "C",
  },
  Internal: {
    value: 3,
    text: "Internal",
    capped: "iNternal",
    letter: "N",
  },
  Overall: {
    value: 4,
    text: "Overall",
    capped: "oVerall",
    letter: "V",
  },
};

enums.MS_TEAM_VISIBILITY = {
  Private: {
    value: 0,
    text: "Private",
    color: "deep-orange",
    textColor: "white",
  },
  Public: {
    value: 1,
    text: "Public",
    color: "indigo",
    textColor: "white",
  },
  HiddenMembership: {
    value: 2,
    text: "Hidden Membership",
    color: "brown",
    textColor: "white",
  },
  UnknownFutureValue: {
    value: 3,
    text: "Unknown Future Value",
    color: "amber",
    textColor: "grey darken-4",
  },
};

enums.PROJECT_TYPE_LIST = [
  enums.PROJECT_TYPE.Build,
  enums.PROJECT_TYPE.Client,
  enums.PROJECT_TYPE.Internal,
  enums.PROJECT_TYPE.Overall,
];

enums.clientStates = {
  Associated: 1,
  Retired: 2,
  Archived: 3,
  "Not Associated with a Client": 4,
};

enums.PROJECT_STAGE = {
  Lead: {
    value: -1,
    text: "Leads",
    textSingle: "Lead",
    icon: "fa-pennant",
    route: {
      path: "/leads",
      name: "leads",
    },
    color: "info",
    textColor: "white",
    PROJECT_STATUS: [
      enums.LEAD_STATUS.Lead,
      enums.LEAD_STATUS.Waiting,
      enums.LEAD_STATUS.Proposal,
      enums.LEAD_STATUS.LeadLost,
      enums.LEAD_STATUS.PausedClient,
      enums.LEAD_STATUS.PausedDNA,
    ],
  },
  Opportunity: {
    value: 1,
    text: "Opportunities",
    textSingle: "Opportunity",
    icon: "fa-lightbulb-on",
    route: {
      path: "/opportunities",
      name: "opportunities",
    },
    color: "amber",
    textColor: "grey darken-4",
    PROJECT_STATUS: [
      enums.PROJECT_STATUS.Proposal_Requested,
      enums.PROJECT_STATUS.Proposal_InProgress,
      enums.PROJECT_STATUS.Proposal_Sent,
      enums.PROJECT_STATUS.Proposal_Update_Requested,
      enums.PROJECT_STATUS.Proposal_Accepted,
      enums.PROJECT_STATUS.Proposal_NotAccepted,
      enums.PROJECT_STATUS.Paused_By_Client,
      enums.PROJECT_STATUS.Paused_By_DNA,
    ],
  },
  Active: {
    value: 2,
    text: "Active Projects",
    textSingle: "Active",
    route: {
      path: "/active-projects",
      name: "active-projects",
    },
    icon: "fa-play",
    color: "green",
    textColor: "white",
    PROJECT_STATUS: [
      enums.PROJECT_STATUS.Project_Design_In_Progress,
      enums.PROJECT_STATUS.Project_Design_Complete,
      enums.PROJECT_STATUS.Project_RFP_Prepared,
      enums.PROJECT_STATUS.Project_RFP_Out,
      enums.PROJECT_STATUS.Project_RFP_Received,
      enums.PROJECT_STATUS.Project_Construction_Administration,
      enums.PROJECT_STATUS.Paused_By_Client,
      enums.PROJECT_STATUS.Paused_By_DNA,
    ],
  },
  Closed: {
    value: 3,
    text: "Closed Projects",
    textSingle: "Closed",
    route: {
      path: "/closed-projects",
      name: "closed-projects",
    },
    icon: "fa-lock-alt",
    color: "pink",
    textColor: "white",
    PROJECT_STATUS: [enums.PROJECT_STATUS.Closed],
  },
  Archived: {
    value: 4,
    text: "Archived Projects",
    textSingle: "Archived",
    route: {
      path: "/archived-projects",
      name: "archived-projects",
    },
    icon: "fa-archive",
    color: "brown",
    textColor: "white",
    PROJECT_STATUS: [enums.PROJECT_STATUS.Archived],
  },
};

enums.PROJECT_STAGE_LIST = [
  enums.PROJECT_STAGE.Opportunity,
  enums.PROJECT_STAGE.Active,
  enums.PROJECT_STAGE.Closed,
  enums.PROJECT_STAGE.Archived,
];

enums.PROJECT_STAGE_LIST_WITH_LEAD = [
  enums.PROJECT_STAGE.Lead,
  enums.PROJECT_STAGE.Opportunity,
  enums.PROJECT_STAGE.Active,
  enums.PROJECT_STAGE.Closed,
  enums.PROJECT_STAGE.Archived,
];

enums.PROVIDERS = {
  AVV: {
    value: "AVV",
    text: "AVV",
    description: "Audio Visual Vendor",
    color: "orange",
    textColor: "white",
  },
  EC: {
    value: "EC",
    text: "EC",
    description: "Electrical Contractor",
    color: "yellow",
    textColor: "grey darken-4",
  },
  LVC: {
    value: "LVC",
    text: "LVC",
    description: "Low Voltage Contractor",
    color: "green",
    textColor: "white",
  },
  GC: {
    value: "GC",
    text: "GC",
    description: "General Contractor",
    color: "grey darken-2",
    textColor: "white",
  },
  FV: {
    value: "FV",
    text: "FV",
    description: "Furniture Vendor",
    color: "brown",
    textColor: "white",
  },
  FBO: {
    value: "FBO",
    text: "FBO",
    description: "Furnished By Others",
    color: "white",
    textColor: "grey darken-2",
  },
  SEC: {
    value: "SEC",
    text: "SEC",
    description: "Security",
    color: "blue",
    textColor: "white",
  },
};

enums.INSTALLERS = {
  AVV: {
    value: "AVV",
    text: "AVV",
    description: "Audio Visual Vendor",
    color: "orange",
    textColor: "white",
  },
  EC: {
    value: "EC",
    text: "EC",
    description: "Electrical Contractor",
    color: "yellow",
    textColor: "grey darken-4",
  },
  LVC: {
    value: "LVC",
    text: "LVC",
    description: "Low Voltage Contractor",
    color: "green",
    textColor: "white",
  },
  GC: {
    value: "GC",
    text: "GC",
    description: "General Contractor",
    color: "grey darken-2",
    textColor: "white",
  },
  FV: {
    value: "FV",
    text: "FV",
    description: "Furniture Vendor",
    color: "brown",
    textColor: "white",
  },
  IBO: {
    value: "IBO",
    text: "IBO",
    description: "Installed By Others",
    color: "white",
    textColor: "grey darken-2",
  },
  SEC: {
    value: "SEC",
    text: "SEC",
    description: "Security",
    color: "blue",
    textColor: "white",
  },
};

enums.EquipmentAvailability = {
  Available: {
    value: 1,
    text: "Available",
    icon: "fa-check",
    color: "blue",
  },
  Presale: {
    value: 2,
    text: "Presale",
    icon: "fa-dollar",
    color: "green",
  },
  Discontinued: {
    value: 3,
    text: "Discontinued",
    icon: "fa-xmark",
    color: "brown",
  },
  RequireReview: {
    value: 4,
    text: "Require Review",
    icon: "fa-question",
    color: "pink",
  },
  Deprecated: {
    value: 5,
    text: "Deprecated",
    icon: "fa-ban",
    color: "red",
  },
};

enums.PowerSpecAvailability = {
  Optional: {
    value: 1,
    text: "Optional",
  },
  Required: {
    value: 2,
    text: "Required",
  },
};

enums.PowerSpecType = {
  AC: {
    value: 1,
    text: "AC Power Spec",
  },
  DC: {
    value: 2,
    text: "DC Power Spec",
  },
  PoE: {
    value: 3,
    text: "PoE Power Spec",
  },
};

enums.PoEType = {
  PoeType1: {
    value: 1,
    text: "PoE Type 1",
  },
  PoeType2: {
    value: 2,
    text: "PoE Type 2",
  },
  PoeType3: {
    value: 3,
    text: "PoE Type 3",
  },
  PoeType4: {
    value: 4,
    text: "PoE Type 4",
  },
};

enums.WirelessProtocolType = {
  W_802_11ax_Wi_Fi6: {
    value: 1,
    text: "802.11ax (Wi-Fi 6)",
  },
  W_802_11ac_wave2: {
    value: 2,
    text: "802.11ac wave2",
  },
  W_802_11ac_wave1: {
    value: 3,
    text: "802.11ac wave1",
  },
  W_802_11n: {
    value: 4,
    text: "802.11n",
  },
  W_802_11g: {
    value: 5,
    text: "802.11g",
  },
  W_802_11a: {
    value: 6,
    text: "802.11a",
  },
  W_802_11b: {
    value: 7,
    text: "802.11b",
  },
  Legacy_802_11: {
    value: 8,
    text: "Legacy 802.11",
  },
};

enums.NetworkPort = {
  RJ45: {
    value: 1,
    interface: "RJ45",
    name: "LAN 1",
    speedMb: 100,
  },
  SFP: {
    value: 2,
    interface: "SFP",
    name: "LAN 2",
    speedMb: 1000,
  },
  SFP_Plus: {
    value: 3,
    interface: "SFP+",
    name: "LAN 3",
    speedMb: 10000,
  },
  SFP28: {
    value: 4,
    interface: "SFP28",
    name: "VOIP",
    speedMb: 100000,
  },
  QSFP_Plus: {
    value: 5,
    interface: "QSFP+",
    name: "1",
    speedMb: 10000,
  },
  QSFP28: {
    value: 6,
    interface: "QSFP28",
    name: "UPLINK",
    speedMb: 100,
  },
  CFP: {
    value: 7,
    interface: "CFP",
    name: "LOCAL",
    speedMb: 100000,
  },
  WSFP_DD: {
    value: 8,
    interface: "WSFP-DD",
    name: "ONE",
    speedMb: 100000,
  },
  SFP_DD: {
    value: 9,
    interface: "SFP-DD",
    name: "LINK",
    speedMb: 100000,
  },
  QSFP: {
    value: 10,
    interface: "QSFP",
    name: "MGMT",
    speedMb: 100000,
  },
};

enums.DownloadStatus = {
  Pending: {
    value: 0,
    text: "Pending",
    icon: "fa-sync",
    iconAlt: "fa-sync",
    color: "light-blue",
    colorHex: "#03A9F4",
    textColor: "white",
  },
  Inprogress: {
    value: 1,
    text: "In progress",
    icon: "fa-play",
    iconAlt: "fa-play",
    color: "orange",
    colorHex: "#FF9800",
    textColor: "white",
  },
  Succeeded: {
    value: 2,
    text: "Succeeded",
    icon: "fa-circle-check",
    iconAlt: "fa-check",
    color: "teal",
    colorHex: "#009688",
    textColor: "white",
  },
  Failed: {
    value: 3,
    text: "Failed",
    icon: "fa-circle-xmark",
    iconAlt: "fa-xmark fa-swap-opacity",
    color: "red",
    colorHex: "#F44336",
    textColor: "white",
  },
};

enums.TemplateType = {
  EquipmentsReport: { value: 0, text: "Equipment Listing", icon: "fa-plug" },
  ProjectReport: { value: 1, text: "Project Report", icon: "fa-folder" },
  WordReport: { value: 2, text: "Word Report", icon: "fa-file-doc" },
  ProjectsList: { value: 3, text: "Projects Listing", icon: "fa-folders" },
  EquipmentGroupsReport: { value: 4, text: "Equipment Groups Listing", icon: "fa-plug" },
  ManufacturerEquipments: { value: 5, text: "Manufacturer Listing", icon: "fa-industry-windows" },
  UsersReport: { value: 6, text: "Users Listing", icon: "fa-user-group" },
  RolesReport: { value: 7, text: "Roles Listing", icon: "fas fa-crown" },
  ContractsReport: {
    value: 8,
    text: "Contracts Listing",
    icon: "fad fa-file-signature fa-swap-opacity mr-2",
  },
};

enums.TechnicalDocumentType = [
  { value: 1, text: "Cutsheet" },
  { value: 2, text: "Install Manual" },
  { value: 3, text: "Specs" },
  { value: 4, text: "Drawing" },
  { value: 5, text: "2D Model" },
  { value: 6, text: "3D Model" },
];

enums.CompanyType = {
  Client: { value: 0, text: "Client" },
  EngineeringFirm: { value: 1, text: "Engineering Firm" },
  GeneralContractor: { value: 2, text: "General Contractor" },
  SubContractor: { value: 3, text: "SubContractor" },
  Manufacture: { value: 4, text: "Manufacture" },
  SystemsIntegrator: { value: 5, text: "Systems Integrator" },
  Representative: { value: 6, text: "Representative" },
  Architect: { value: 7, text: "Architect" },
  NotSupported: { value: 8, text: "Not Supported" },
};

enums.NAMING_TEMPLATE_SEGMENTS = [
  {
    name: "Space Name",
    templateSegment: "{{SpaceName}}",
    templateSegmentName: "SpaceName",
    templateSegmentSample: "Meeting Room",
    color: "deep-purple",
    order: null,
  },
  {
    name: "Space Number",
    templateSegment: "{{SpaceNumber}}",
    templateSegmentName: "SpaceNumber",
    templateSegmentSample: "M1",
    color: "purple",
    order: null,
  },
  {
    name: "Level Name",
    templateSegment: "{{LevelName}}",
    templateSegmentName: "LevelName",
    templateSegmentSample: "Level 1",
    color: "lime",
    order: null,
  },
  {
    name: "Level Short Name",
    templateSegment: "{{LevelShortName}}",
    templateSegmentName: "LevelShortName",
    templateSegmentSample: "L1",
    color: "yellow",
    order: null,
  },
  {
    name: "Building Name",
    templateSegment: "{{BuildingName}}",
    templateSegmentName: "BuildingName",
    templateSegmentSample: "Building 1",
    color: "green",
    order: null,
  },
  {
    name: "Building Short Name",
    templateSegment: "{{BuildingShortName}}",
    templateSegmentName: "BuildingShortName",
    templateSegmentSample: "B1",
    color: "light-green",
    order: null,
  },
  {
    name: "Document Type",
    templateSegment: "{{DocumentType}}",
    templateSegmentName: "DocumentType",
    templateSegmentSample: "SCD",
    color: "red",
    order: null,
  },
  {
    name: "Document Sequence",
    templateSegment: "{{Sequence}}",
    templateSegmentName: "Sequence",
    templateSegmentSample: "3",
    color: "deep-orange",
    order: null,
  },
  {
    name: "Client Name",
    templateSegment: "{{ClientName}}",
    templateSegmentName: "ClientName",
    templateSegmentSample: "Apple",
    color: "blue",
    order: null,
  },
  {
    name: "Client Abbreviated Name",
    templateSegment: "{{ClientAbbreviatedName}}",
    templateSegmentName: "ClientAbbreviatedName",
    templateSegmentSample: "APL",
    color: "cyan",
    order: null,
  },
  {
    name: "Equipment Category Name",
    templateSegment: "{{EquipmentCategoryName}}",
    templateSegmentName: "EquipmentCategoryName",
    templateSegmentSample: "Network",
    order: null,
    color: "orange",
  },
  {
    name: "Equipment Category Number",
    templateSegment: "{{EquipmentCategoryNumber}}",
    templateSegmentName: "EquipmentCategoryNumber",
    templateSegmentSample: "1",
    color: "brown",
    order: null,
  },
  {
    name: "Text",
    templateSegment: "{{Text(VALUE)}}",
    templateSegmentName: "Text",
    segmentValue: null,
    needValue: true,
    color: "blue-grey",
    order: null,
  },
];

enums.ConstructionPhaseRecordType = {
  AssignedUser: { value: 0, text: "Assigned User" },
  GenericEntry: { value: 1, text: "Generic Entry" },
};

enums.LinkedProjectStatus = {
  Idle: {
    value: 0,
    text: "Idle",
  },
  Pending: {
    value: 1,
    text: "Pending",
  },
  Inprogress: {
    value: 2,
    text: "In Progress",
  },
  Succeeded: {
    value: 3,
    text: "Succeeded",
  },
  Failed: {
    value: 4,
    text: "Failed",
  },
};

enums.ResourceOfferState = {
  Draft: {
    value: 0,
    text: "Draft",
    icon: "fa-pen",
    color: "blue-grey",
    textColor: "white",
  },
  PendingTarget: {
    value: 1,
    text: "Pending Target",
    icon: "fa-clock",
    color: "orange",
    textColor: "secondary",
  },
  Declined: {
    value: 2,
    text: "Declined By Target",
    icon: "fa-ban",
    color: "error",
    textColor: "white",
  },
  PendingManagement: {
    value: 3,
    text: "Pending Management",
    icon: "fa-gavel",
    color: "orange",
    textColor: "secondary",
  },
  Approved: {
    value: 4,
    text: "Approved",
    icon: "fa-check-double",
    color: "cyan",
    textColor: "white",
  },
  Rejected: {
    value: 5,
    text: "Rejected By Management",
    icon: "fa-power-off",
    color: "pink",
    textColor: "white",
  },
};

enums.ResourceShiftRequestState = {
  Draft: {
    value: 0,
    text: "Draft",
    icon: "fa-pen",
    color: "blue-grey",
    textColor: "white",
  },
  PendingManagement: {
    value: 1,
    text: "Pending Management",
    icon: "fa-gavel",
    color: "orange",
    textColor: "secondary",
  },
  Approved: {
    value: 2,
    text: "Approved",
    icon: "fa-check-double",
    color: "cyan",
    textColor: "white",
  },
  Rejected: {
    value: 3,
    text: "Rejected",
    icon: "fa-power-off",
    color: "pink",
    textColor: "white",
  },
};

enums.FILTER_TYPE = {
  Divider: { value: 0, text: "Divider" },
  User: { value: 1, text: "User" },
  ProjectSpaceCreatedBy: { value: 2, text: "Created By", description: "Project Space Created By" },
  ProjectSpaceUpdatedBy: { value: 3, text: "Updated By", description: "Project Space Updated By" },
  Provider: { value: 4, text: "Provider" },
  Installer: { value: 5, text: "Installer" },
  EquipmentCategory: { value: 6, text: "EquipmentCategory" },
  Building: { value: 7, text: "Building" },
  Level: { value: 8, text: "Level" },
  Manufacturer: { value: 9, text: "Manufacturer" },
  ProductFamily: { value: 10, text: "Product Family" },
  SpaceTemplate: { value: 11, text: "Space Template" },
  Client: { value: 12, text: "Client" },
  Role: { value: 13, text: "Role" },
  ExternalRole: { value: 14, text: "External Role" },
  ProjectStatus: { value: 15, text: "Project Status" },
  LeadStatus: { value: 16, text: "Lead Status" },
  ContractType: { value: 17, text: "Contract Type" },
  ContractOrigin: { value: 18, text: "Contract Origin" },
  ContractStatus: { value: 19, text: "Contract Status" },
  DownloadType: { value: 20, text: "Download Type" },
};

enums.ContractType = {
  Proposal: {
    value: 1,
    text: "Proposal",
    icon: "fa-file-signature",
    color: "deep-purple",
    textColor: "white",
  },
  Offer: {
    value: 2,
    text: "Offer",
    icon: "fa-envelope",
    color: "deep-orange darken-1",
    textColor: "white",
  },
  ShiftRequest: {
    value: 3,
    text: "Shift Request",
    icon: "fa-arrows-turn-right",
    color: "cyan darken-1",
    textColor: "white",
  },
};

enums.ContractOrigin = {
  Manual: {
    value: 1,
    text: "Manual",
    icon: "fa-screwdriver-wrench",
    color: "blue accent-4",
    textColor: "white",
  },
  Automated: {
    value: 2,
    text: "Automated",
    icon: "fa-bolt-auto",
    color: "orange",
    textColor: "secondary",
  },
};

export default enums;
